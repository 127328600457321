.trade-machine {
  color: #2e2e2e;
  font-family: Arial, sans-serif;
}

.trade-machine-header {
  background-color: ##f2f2f2;
}

.instructions {
  margin-bottom: 20px;
}

.instructions h3 {
  margin-top: 0;
}

.team-details {
  margin: 5px;
}

.team-details select {
  display: block;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.team-selection {
  display: flex;
  justify-content: space-around;
  padding: 10px;
}

.team-section {
  width: 45%;
  margin: 0 5px;
}

.team-section select {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 5px;
}

.player-selection h4 {
  margin-top: 10px;
}

/* Trade Machine Container */
.trade-machine {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Trade Summary */
.trade-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

/* Team Summary */
.team-summary {
  background-color: #fbf6fe;
  border-radius: 10px;
  padding: 10px 20px;
  width: 200px;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 5px 5px;
}

.team-summary h4 {
  font-size: 1.5em;
  margin: 5px;
}

.team-summary button {
  color: #8b2acd;
  padding: 2px 8px;
  font-size: 12px;
  margin-left: 10px;
  cursor: pointer;
}

.check { 
  height: 50px; 
  width: 18px; 
  border-bottom: 10px solid green; 
  border-right: 10px solid green; 
  transform: rotate(45deg); 
  margin: 20px; 
} 

/* Try This Trade Button */
.trade-restart button {
  padding: 5px 10px;
  background-color: transparent;
  color: #8b2acd;
  border: solid 1px #8b2acd;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
}


.trade-restart button:hover {
  background-color: #8b2acd;
  color: white;
}


.required-trade-value {
  margin: 5px;
  font-size: 1.5em;
}

.required-trade-match {
  margin: 10px;
}

.trade-result {
  text-align: center;
}

.trade-for {
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: bold;
}

.trade-approved {
  color: green; 
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 8px;
}
