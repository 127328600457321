.page-section-top {
  padding: 20px;
}

h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #262626;
}

p {
  font-size: 16px;
  margin-bottom: 15px;
  color: #7D7D7D;
}

.draft-results {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 30px;
}

.draft-card {
  background-color: #fbfafb;
  border-radius: 10px;
  width: 200px;
  text-align: left;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.draft-card-header {
  display: flex;
  align-items: center;
  justify-content: left;
  background-image: linear-gradient(140deg, #9230e3, #1b1598);
  padding: 10px;
  border-radius: 10px 10px 0 0;

}

.team-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.team-name {
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  font-family: "Jost", sans-serif;
  letter-spacing: 0.3px;
}

.player-info {
  margin-bottom: 10px;
  padding: 10px;
}

.player-name {
  font-size: 23px;
  font-weight: 800;
  font-style: italic;
  color: #060225;
  margin-bottom: 8px;
  font-family: "Jost", sans-serif;
  min-width: 150px;
  letter-spacing: -0.5px;
}

.player-team {
    margin-bottom: 5px;
    font-size: small;
    color: #a646f9;
}

.position-height {
  display: flex;
  justify-content: space-between;
  font-size: small;
  color: #868686;
}

.position, .height {
  flex: 1;
}

.draft-pick {
  font-size: 77px;
  font-weight: bold;
  color: #4b49ec45;
  font-family: 'Jost', sans-serif;
  text-align: right;
  padding: 0px 10px;
}

.key-attributes {
  margin: 0px 5px;
}

.attributes-list {
  margin-top: 10px;
}

.label {
  display: inline-block;
  padding: 3px 6px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 10px;
  border-radius: 20px;
  font-family: "Jost", sans-serif;
  text-transform: capitalize;
}

.label:nth-child(1) {
  color: #ac48fd;
  border: 1px solid #8b2acd;
}

.label:nth-child(2) {
  color: #4b49ec;
  border: 1px solid #4b49ec;
}

.label:nth-child(3) {
  color: #2c8e9a;
  border: 1px solid #2c8e9a;
}

/* Optional labels for more attributes */
.label:nth-child(4) {
  background-color: #ffc107; /* Warning label */
}

.label:nth-child(5) {
  background-color: #17a2b8; /* Info label */
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  .draft-card {
    width:90%;
  }

  .team-name {
    font-size: 20px;
  }

  .player-name {
    font-size: 36px;
  }

  .player-team {
    font-size: medium;
  }

  .position-height {
    font-size: medium;
  }

  .label {
    font-size: 12px;
    
  }
}


