html {
  width: 100%;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #FFFFFF;
  color: #000000;
}

header {
  background-image: linear-gradient(140deg, #ac48fd, #22209d);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  flex-wrap: wrap; /* Allow the header to wrap content */
}

header .logo {
  font-size: 30px;
  font-weight: 300;
  color: white;
  margin-left: 10px;
}

header .logo-tank {
  font-size: 40px;
  font-weight: 700;
  font-style: italic;
  letter-spacing: -1px;
}

header a {
  color: white;
  font-size: 1em;
  text-decoration: none;
}

nav {
  padding: 20px;
  font-family: "Jost", sans-serif;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to a new line on small screens */
}

nav ul li {
  margin-left: 20px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

/* Media Query for Small Screens */
@media (max-width: 768px) {
  nav ul {
    justify-content: center; /* Center the navigation items on smaller screens */
  }

  nav ul li {
    margin-left: 10px;
    margin-right: 10px;
  }

  header {
    justify-content: center;
  }

  header .logo {
    margin-left: 10px;
    font-size: 1.5rem; /* Adjust logo size on smaller screens */
  }

  header a {
    color: white;
    font-size: 1em;
    text-decoration: none;
  }

  nav ul li a {
    font-size: 14px;
  }
}

h1 {
  color: #FF0000;
  margin: 10px 0 10px 0;
  font-family: "Jost", sans-serif;
  font-weight: 800;
  font-style: italic;
  letter-spacing: -1px;
}

p {
  margin: 5px 0 20px 0;
}

button {
  color: #8b2acd;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #ffffff;
  border: solid 2px #8b2acd;
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

button:hover {
  background-color: #8b2acd;
  color: white;
}  

button:disabled {
  background-color: #C9C9C9;
  cursor: not-allowed;
}

table {
  margin: 20px auto; /* Centering table with automatic margins */
  border-collapse: collapse;
  overflow-x: auto;
  display: block;
  justify-content: center;
}

table th, table td {
  padding: 10px;
  text-align: left;
  display: 'flex'; 
  alignItems: 'center';
}

table th {
  background-color: #1cb4d2;
  color: white;
  font-family: "Jost", sans-serif;
  font-style: italic;
}

table tbody tr:nth-child(even) {
  background-color: #F2F2F2;
}

/* Footer Styles */
footer {
  background-color: #f7f3ee;
  padding: 50px 0px;
  text-align: center;
  margin-top: 40px;
  position: relative;
  width: 100%;
  bottom: 0;
}

footer .links {
  margin-top: 30px;
  margin-bottom: 10px;
}

footer .links a {
  margin: 15px;
  color: #8b2acd;
  text-decoration: none;
  font-weight: bold;
}

footer .links a:hover {
  text-decoration: underline;
}

footer .footer-feedback {
  margin-top: 40px;
}

footer .footer-feedback a {
  color: black;
}

footer p.copyright {
  color: #7d7d7d;
}

.beta-version-banner {
  margin: 12px;
  text-align: center;
}

.beta-version-banner p {
  color: #8b2acd;
  margin: 0px;
  font-size: small;
}

.loader {
  border: 8px solid #fbf6fe;
  border-radius: 50%;
  border-top: 8px solid #8b2acd;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: 40px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.page-section-top h1 p select {
  text-align: center;
}

.page-section-top select {
  margin: 20px;
  font-size: 1.1em;
  padding: 10px;
  color: #8b2acd;
  background-color: #ffffff;
  border: solid 2px #8b2acd;
}

.player-profile {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f3ee;
  border-radius: 10px;
}

.player-profile h1 {
  font-size: 3.5rem;
  margin: 5px;
}

.player-profile h2 {
  font-size: 2.5rem;
  color: #4b49ec;
  margin: 10px;
}

.player-profile p {
  font-size: 1.2rem;
  margin: 25px 0px 35px 0px;
}

.team-info {
  font-size: 1.3em;
  margin-bottom: 10px;
}

.team-info img {
  margin: 20px 0px;
}

.attributes {
  display: flex;
  text-align: center;
}

.attribute {
  padding: 13px;
  background-color: #1cb4d2;
  margin: 5px;
  border-radius: 20px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 0.9em;
}

/* Roster Analysis Styles */
.roster-filter {
  margin-bottom: 20px;
  text-align: center;
}

.roster-filter label {
  margin-right: 10px;
  font-weight: bold;
  font-family: "Jost", sans-serif;
  font-size: 1.1em;
  color: #333;
}

.roster-filter select {
  padding: 8px;
  font-size: 1.1em;
  border-radius: 5px;
  color: #8b2acd;
  background-color: #ffffff;
  border: solid 2px #8b2acd;
}

.roster-analysis-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.roster-analysis-card {
  flex: 1 1 300px;
  max-width: 400px;
  background-color: #f7f3ee;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.roster-analysis-card h3 {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-family: "Jost", sans-serif;
  font-weight: 600;
  color: #333;
}

.analysis-table {
  width: 100%;
  border-collapse: collapse;
}

.analysis-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  font-family: Arial, sans-serif;
  font-size: 0.9em;
}

.analysis-table td:first-child {
  font-weight: bold;
  width: 40%;
  color: #8b2acd;
}

.no-data {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #7d7d7d;
}

.loading, .error {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
}

.error {
  color: #d32f2f;
}